import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import useAuth from '~/hooks/useAuth';
import api from '~/services/api';
import moment from 'moment';
import { useForm } from 'react-hook-form';

import {
  FormFiltros,
  Finalizadora,
  Select,
  FormQuitacao,
  Parcela,
  Conta,
  UseFormProps,
} from './protocols';
import { ReceiptData } from './protocols/receipt.data';
import { schema } from './validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { transformAsCurrency } from '~/utils/functions';

moment.locale('pt-br');

type QuitacaoContextData = {
  finalizadoras: Select[];
  onChangeFormFiltros: (form: FormFiltros) => void;
  formFiltros: FormFiltros;
  onChangeFormQuitacao: (form: FormQuitacao) => void;
  formQuitacao: FormQuitacao;
  parcelas: Parcela[];
  setParcelas: React.Dispatch<React.SetStateAction<Parcela[]>>;
  onChangeParcelas: (items: Parcela[]) => void;
  selectedParcelas: Parcela[];
  onChangeSelectedParcelas: (items: Parcela[]) => void;
  total: number;
  contas: Select[];
  handleInit: (value: boolean) => void;
  init: boolean;
  handleInitPesquisar: (value: boolean) => void;
  initPesquisar: boolean;
  handleInitData: (value: boolean) => void;
  initData: boolean;
  handleClearFormFilters: () => void;
  disabledFields: boolean;
  tipoCategoria: string;
  handleTipoCategoria: (value: string) => void;
  handleDisabledFields: (value: boolean) => void;
  handleCancelFilters: () => void;
  handleClearFormQuitacao: () => void;
  handleClearOptions: () => void;
  clearOptions: boolean;
  receiptData: ReceiptData;
  setReceiptData: React.Dispatch<React.SetStateAction<ReceiptData>>;
  formTela: UseFormProps;
  setLoader: (val: boolean) => void;
  loader: boolean;
  flg_calc_juros: boolean;
  handleJurosParcelas: (items?: Parcela[]) => void;
  showModalRecibo: boolean;
  setShowModalRecibo: React.Dispatch<React.SetStateAction<boolean>>;

  loadingTableTitulos: boolean;
  setLoadingTableTitulos: React.Dispatch<React.SetStateAction<boolean>>;

  recibo: string;
  setRecibo: React.Dispatch<React.SetStateAction<string>>;
};

export const QuitacaoContext = createContext({} as QuitacaoContextData);

interface QuitacaoContextProviderProps {
  children: ReactNode;
}

export function QuitacaoContextProvider({
  children,
}: QuitacaoContextProviderProps): JSX.Element {
  const { user } = useAuth();
  const formFiltrosBlank: FormFiltros = {
    cod_loja: {
      value: user?.loja ? [user?.loja] : [],
      isInvalid: false,
      isRequired: false,
    },
    cod_pessoa: {
      value: { label: '', value: undefined },
      isInvalid: true,
      isRequired: true,
    },
    cod_finalizadora: {
      value: { label: '', value: undefined },
      isInvalid: false,
      isRequired: false,
    },
    cod_categoria: {
      value: { label: '', value: undefined },
      isInvalid: false,
      isRequired: false,
    },
    tipo_conta: {
      value: 1,
      isInvalid: false,
      isRequired: false,
    },
    tipo_periodo: {
      value: 1,
      isInvalid: false,
      isRequired: false,
    },
    dta_ini: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
    dta_fim: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
  };

  const formQuitacaoBlank: FormQuitacao = {
    cod_finalizadora: {
      value: { label: '', value: undefined },
      isInvalid: false,
      isRequired: false,
    },
    cod_cc: {
      value: { label: '', value: undefined },
      isInvalid: true,
      isRequired: true,
    },
    dta_quitacao: {
      value: moment().format('YYYY-MM-DD'),
      isInvalid: false,
      isRequired: true,
    },
  };
  const {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    clearErrors,
    watch,
    setError,
  } = useForm({ resolver: yupResolver(schema), reValidateMode: 'onBlur' });

  const flg_calc_juros = watch('flg_calc_juros');

  const formTela: UseFormProps = {
    register,
    getValues,
    handleSubmit,
    formState,
    control,
    setValue,
    clearErrors,
    setError,
  };
  const [init, setInit] = useState(false);
  const [initPesquisar, setInitPesquisar] = useState(false);
  const [initData, setInitData] = useState(false);
  const [formFiltros, setFormFiltros] = useState<FormFiltros>(formFiltrosBlank);
  const [formQuitacao, setFormQuitacao] =
    useState<FormQuitacao>(formQuitacaoBlank);
  const [finalizadoras, setFinalizadoras] = useState<Select[]>([]);
  const [parcelas, setParcelas] = useState<Parcela[]>([]);
  const [selectedParcelas, setSelectedParcelas] = useState<Parcela[]>([]);
  const [contas, setContas] = useState<Select[]>([]);
  const [total, setTotal] = useState(0);
  const [disabledFields, setDisabledFields] = useState(false);
  const [tipoCategoria, setTipoCategoria] = useState('Receita');
  const [clearOptions, setClearOptions] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [receiptData, setReceiptData] = useState<ReceiptData>(
    {} as ReceiptData,
  );
  const [loadingTableTitulos, setLoadingTableTitulos] =
    useState<boolean>(false);
  const [showModalRecibo, setShowModalRecibo] = useState(false);
  const [recibo, setRecibo] = useState('');

  useEffect(() => {
    setValue('dta_quitacao', formQuitacao.dta_quitacao.value);
  }, [formQuitacao.dta_quitacao.value]);

  const onChangeFormFiltros = useCallback((form: FormFiltros) => {
    setFormFiltros(form);
  }, []);

  const onChangeFormQuitacao = useCallback((form: FormQuitacao) => {
    setFormQuitacao(form);
  }, []);

  const getFinalizadoras = useCallback(
    async (lojas: number | number[] | undefined = user?.loja) => {
      const res = await api.get(`/quitacao/finalizadoras/${lojas}`);
      const { data } = res.data;
      const options: Select[] = data.map((item: Finalizadora) => {
        return {
          value: item.cod_finalizadora,
          label: item.des_finalizadora,
        };
      });
      setFinalizadoras(options);
    },
    [],
  );

  const getContas = useCallback(
    async (lojas: number | number[] | undefined = user?.loja) => {
      const res = await api.get(`/quitacao/contas/${lojas}`);
      const { data } = res.data;
      const options: Select[] = data.map((item: Conta) => {
        return {
          label: `${item.cod_banco} - (${item.des_cc})`,
          value: item.cod_cc,
        };
      });
      setContas(options);
    },
    [],
  );

  useEffect(() => {
    const lojas = formFiltros.cod_loja.value;
    if (Array.isArray(lojas) && lojas.length < 1) {
      return setFinalizadoras([]);
    }
    if (!lojas) return setFinalizadoras([]);
    getFinalizadoras(lojas);
    getContas(lojas);
  }, [formFiltros.cod_loja.value, getFinalizadoras, getContas]);

  const onChangeParcelas = async (items: Parcela[]) => {
    const flgCalcJuros = getValues('flg_calc_juros');

    if (flgCalcJuros) {
      handleJurosParcelas(items);
    } else {
      const parcelaWithJuros = await Promise.all(
        items.map(async (parcela) => {
          parcela.val_juros = 0;
          return parcela;
        }),
      );

      setParcelas(parcelaWithJuros);
    }
  };

  const calcSelecionados = () => {
    let sum = 0;
    selectedParcelas.map((parcela) => {
      sum += parcela.val_parcela + parcela.val_juros;
      return parcela;
    });

    setTotal(sum);
  };

  useEffect(() => {
    calcSelecionados();
  }, [selectedParcelas]);

  const onChangeSelectedParcelas = (items: Parcela[]) => {
    setSelectedParcelas(items);
  };

  const handleInit = (value: boolean): void => {
    setInit(value);
  };

  const handleInitPesquisar = (value: boolean): void => {
    setInitPesquisar(value);
  };
  const handleInitData = (value: boolean): void => {
    setInitData(value);
  };

  const handleClearFormFilters = useCallback(() => {
    const blankForm = {
      ...formFiltrosBlank,
      cod_loja: {
        ...formFiltros.cod_loja,
      },
    };

    setClearOptions(true);
    setFormFiltros(blankForm);
    setTotal(0);
    setSelectedParcelas([]);
    setParcelas([]);
    setDisabledFields(false);
    setInit(false);
    setInitPesquisar(false);
    setValue('flg_calc_juros', false);
  }, [formFiltros.cod_loja, formFiltrosBlank, setValue]);

  const handleClearOptions = () => {
    setClearOptions(false);
  };

  const handleClearFormQuitacao = () => {
    setFormQuitacao(formQuitacaoBlank);
    setValue('flg_calc_juros', false);
  };

  const handleDisabledFields = (value: boolean) => {
    setDisabledFields(value);
  };

  const handleTipoCategoria = (value: string) => {
    setTipoCategoria(value);
  };

  const handleCancelFilters = () => {
    setTotal(0);
    setInit(false);
    setFormQuitacao(formQuitacaoBlank);
    setSelectedParcelas([]);
    setParcelas([]);
    setDisabledFields(false);
    setValue('flg_calc_juros', false);
  };

  const handleJurosParcelas = async (items?: Parcela[]) => {
    const flgCalcJuros = getValues('flg_calc_juros');
    const dtaQuitacao = getValues('dta_quitacao');
    const parcelaArray = items || parcelas;
    if (flgCalcJuros) {
      const parcelaWithJuros = await Promise.all(
        parcelaArray.map(async (parcela) => {
          const { data } = await api.post(`/quitacao/val-juros`, {
            cod_loja: parcela.cod_loja,
            val_parcela: transformAsCurrency(parcela.val_parcela),
            dta_vencimento: parcela.dta_vencimento,
            dta_quitada: dtaQuitacao,
          });

          parcela.val_juros = data.data.val_juros;
          return parcela;
        }),
      );
      setParcelas(parcelaWithJuros);
    } else {
      const parcelaWithJuros = await Promise.all(
        parcelaArray.map(async (parcela) => {
          parcela.val_juros = 0;
          return parcela;
        }),
      );

      setParcelas(parcelaWithJuros);
    }

    calcSelecionados();
  };

  return (
    <QuitacaoContext.Provider
      value={{
        finalizadoras,
        onChangeFormFiltros,
        formFiltros,
        onChangeFormQuitacao,
        formQuitacao,
        onChangeParcelas,
        parcelas,
        onChangeSelectedParcelas,
        selectedParcelas,
        init,
        handleInit,
        initPesquisar,
        handleInitPesquisar,
        handleInitData,
        initData,
        total,
        contas,
        handleClearFormFilters,
        disabledFields,
        handleDisabledFields,
        handleCancelFilters,
        handleClearFormQuitacao,
        tipoCategoria,
        handleTipoCategoria,
        handleClearOptions,
        clearOptions,
        receiptData,
        setReceiptData,
        formTela,
        setLoader,
        loader,
        flg_calc_juros,
        handleJurosParcelas,
        loadingTableTitulos,
        setLoadingTableTitulos,
        showModalRecibo,
        setShowModalRecibo,

        recibo,
        setRecibo,
        setParcelas,
      }}
    >
      {children}
    </QuitacaoContext.Provider>
  );
}

export const useQuitacao = (): QuitacaoContextData => {
  return useContext(QuitacaoContext);
};
