import styled from 'styled-components';

export const Text = styled.span`
  font-size: 1.25rem;
`;
export const Title = styled.p`
  margin-top: 10px;
  margin-left: 0px;
  position: absolute;
  font-size: 0.8571428571428571em;
  font-style: inherit;
  line-height: 1.3333333333333333;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  font-weight: 600;
  display: inline-block;
  margin-bottom: 4px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;
