import React from 'react';

import { FormFiltros } from './components/FormFiltros';
import { TableTitulos } from './components/TableTitulos';
import { FormQuitacao } from './components/FormQuitacao';

import { Container } from './styles';
import FooterDefault from '~/components/FooterDefault';
import DefaultLoader from '~/components/DefaultLoader';
import { useQuitacao } from './QuitacaoContext';

const QuitacaoEmLote: React.FC = () => {
  const { loader } = useQuitacao();

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }
  return (
    <Container>
      <h3>QUITAÇÃO EM LOTE</h3>
      <FormFiltros />
      <TableTitulos />
      <FormQuitacao />
      <br />
      <FooterDefault codTela={55} />
    </Container>
  );
};

export default QuitacaoEmLote;
