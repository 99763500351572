import React, { ChangeEvent, useRef } from 'react';

import { Row, Col } from 'react-bootstrap';

import { useQuitacao } from '../../QuitacaoContext';

import { Select } from '../../protocols';
import ContaBanco from '~/components/ContaBanco';

import { Text, Title } from './styles';
import Separator from '~/components/Separator';

import { FormActions } from './FormActions';
import { InpuTypeDate } from '~/components/Pessoa/styles';
import moment from 'moment';
import ToggleDefault from '~/components/ToggleDefault';

export const FormQuitacao: React.FC = () => {
  const {
    disabledFields,
    formQuitacao,
    formTela,
    onChangeFormQuitacao,
    formFiltros,
    total,
    contas,
    init,
    flg_calc_juros,
    tipoCategoria,
    handleJurosParcelas,
  } = useQuitacao();

  const timeoutRef = useRef<number | null>(null);

  // Essa função debouncedHandleJuros evita que seja feita uma requisição
  // a cada caractere digitado no input data. Em vez disso, espera 500ms após o último
  // caractere antes de realizar a requisição.
  const debouncedHandleJuros = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      handleJurosParcelas();
    }, 500);
  };

  return (
    <>
      <Row className="d-flex items-start">
        <Col md={9} sm={12}>
          <Separator labelText="Dados da Quitação" />
        </Col>
        <Col md={3} sm={12} style={{ marginTop: '0.5rem' }}>
          <Text
            style={{
              fontSize: '1.25rem',
            }}
          >
            Selecionados: R${' '}
            {total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </Text>
        </Col>
      </Row>

      <Row className="d-flex content-center">
        <Col sm={12} md={3}>
          <ContaBanco
            value={
              contas.find((item: Select) => {
                return item.value === formQuitacao.cod_cc.value.value;
              }) ?? {
                value: undefined,
                label: 'Informe a Conta',
              }
            }
            isRequired={formQuitacao.cod_cc.isRequired}
            setInvalid={formQuitacao.cod_cc.isInvalid}
            iniInicializado={init}
            onChange={(value: Select) => {
              onChangeFormQuitacao({
                ...formQuitacao,
                cod_cc: {
                  ...formQuitacao.cod_cc,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
            isDisabled={!disabledFields}
            loja={formFiltros.cod_loja.value}
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Title
            style={{
              marginTop: '10px',
              marginLeft: '0px',
              position: 'absolute',
            }}
          >
            Quitar em
          </Title>

          <InpuTypeDate
            style={{
              borderBottom:
                formQuitacao.dta_quitacao.isInvalid && init
                  ? '2px solid #cf1919'
                  : '',
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const isInvalid = !moment(e.target.value).isValid();
              onChangeFormQuitacao({
                ...formQuitacao,
                dta_quitacao: {
                  ...formQuitacao.dta_quitacao,
                  value: e.target.value,
                  isInvalid,
                },
              });

              formTela.setValue('dta_quitacao', e.target.value);
              if (e.target.value) {
                debouncedHandleJuros();
              }
            }}
            disabled={!disabledFields}
            value={formQuitacao.dta_quitacao.value}
            type="date"
          />
        </Col>
        {tipoCategoria === 'Receita' && (
          <Col sm={12} md={3} lg={2}>
            <ToggleDefault
              labelText="Calc. Juros?"
              setChecked={flg_calc_juros}
              disabled={!formQuitacao.dta_quitacao.value}
              onSwitch={() => {
                formTela.setValue('flg_calc_juros', !flg_calc_juros);
                handleJurosParcelas();
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col
          sm={12}
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <FormActions />
        </Col>
      </Row>
    </>
  );
};
