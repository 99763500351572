import React from 'react';
import Loja from '~/components/Loja';

import { useQuitacao } from '~/pages/QuitacaoEmLote/QuitacaoContext';
import useAuth from '~/hooks/useAuth';

const Lojas: React.FC = () => {
  const { formFiltros, onChangeFormFiltros, disabledFields } = useQuitacao();
  const { user } = useAuth();
  return (
    <Loja
      isMulti
      disabled={disabledFields}
      onChange={(val) => {
        if (Array.isArray(val) && val.length < 1) {
          onChangeFormFiltros({
            ...formFiltros,
            cod_loja: {
              ...formFiltros.cod_loja,
              value: user?.loja,
            },
          });
        } else {
          onChangeFormFiltros({
            ...formFiltros,
            cod_loja: {
              ...formFiltros.cod_loja,
              value: val,
            },
          });
        }
      }}
      selectedLoja={formFiltros.cod_loja.value}
    />
  );
};

export default Lojas;
