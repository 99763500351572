import styled from 'styled-components';

export const Container = styled.div`
  .containerPeriodo {
    button {
      width: 50%;
      paading: 10px;
    }
  }
  .containerTipo {
    button {
      width: 50%;
    }
  }

  @media screen and (max-width: 1200px) {
    .containerTipo {
      width: 30%;

      button {
        width: 50%;
      }
    }
    .containerPeriodo {
      width: 30%;
      button {
        width: 50%;
      }
    }
    .colDataContainer {
      width: 20%;
    }
    .containerButtonAction {
      width: 100%;
      justify-content: flex-end !important;
      margin-top: 10px;
      button {
        max-width: 150px;
        margin-left: 5px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .containerTipo {
      width: 50%;

      button {
        width: 50%;
      }
    }
    .containerPeriodo {
      width: 50%;
      button {
        width: 50%;
      }
    }
    .colDataContainer {
      width: 50%;
    }
    .containerButtonAction {
      width: 100%;
      justify-content: flex-end !important;
      margin-top: 10px;
      button {
        max-width: 150px;
        margin-left: 5px;
      }
    }
  }
`;

export const BtnGroupContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 0;
  .btn-group {
    display: flex;
    justify-content: center;
    border-radius: 3px;
    background-color: #ecedf0;
    color: rgb(37, 56, 88);
    border: none;
    font-size: 0.9rem;
    min-width: 50px;
    padding-top: 9px;
    height: 40px;

    &:active {
      outline: none;
      box-shadow: none !important;
    }
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }

  .btn-group-active {
    background-color: #57069e;
    color: #ecedf0;
  }
`;

export const ContainerDate = styled.div`
  .alert-class {
    border-bottom: 2px solid red !important;
    border-radius: 5px !important;
  }
`;

export const Title = styled.p`
  margin-top: 10px;
  margin-left: 0px;
  position: absolute;
  font-size: 0.8571428571428571em;
  font-style: inherit;
  line-height: 1.3333333333333333;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  font-weight: 600;
  display: inline-block;
  margin-bottom: 4px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const InpuTypeDate = styled.input`
  height: 40px;
  margin-top: 30px;
  background-color: #fafbfc;
  border: 2px solid;
  border-color: #dfe1e6;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 20px;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0;
  /* outline: none; */
  width: 100%;
  line-height: 1.4285714285714286;
  &:disabled {
    cursor: not-allowed !important;
    color: gray;
  }
`;
