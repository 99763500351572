import * as yup from 'yup';

export const schema = yup.object().shape({
  cod_pessoa: yup
    .object()
    .shape({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .required(),
});
