import React from 'react';
import QuitacaoContent from './QuitacaoContent';
import { QuitacaoContextProvider } from './QuitacaoContext';

const QuitacaoEmLote: React.FC = () => {
  return (
    <QuitacaoContextProvider>
      <QuitacaoContent />
    </QuitacaoContextProvider>
  );
};

export default QuitacaoEmLote;
