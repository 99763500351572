import React, { ChangeEvent } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import Lojas from './Lojas';
import { Select } from '../../protocols';
import { BuscaParceiro } from '~/components/NovosInputs';
import { useQuitacao } from '../../QuitacaoContext';
import InputSelect from '~/components/Inputs/InputSelect';
import Categoria from '~/components/Categoria';
import FormActions from './FormActions';

import {
  BtnGroupContainer,
  Title,
  InpuTypeDate,
  ContainerDate,
  Container,
} from './styles';
import { Field } from '@atlaskit/form';
import moment from 'moment';

export const FormFiltros: React.FC = () => {
  const {
    formFiltros,
    onChangeFormFiltros,
    finalizadoras,
    disabledFields,
    handleTipoCategoria,
    tipoCategoria,
    handleClearOptions,
    initData,
    formTela: {
      control,
      register,
      setValue,
      clearErrors,
      formState: { errors },
    },
  } = useQuitacao();

  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <Lojas />
        </Col>

        <Col
          sm={12}
          md={5}
          style={{
            zIndex: 1,
          }}
        >
          <BuscaParceiro
            label="Parceiro"
            placeholder="Selecione..."
            name="cod_pessoa"
            register={register}
            isError={!!errors.cod_pessoa}
            control={control}
            customOptions={{
              buscarPor: { cliente: true, loja: true, fornecedor: true },
            }}
            changeSelected={(selected: any) => {
              clearErrors('cod_pessoa');
              setValue('cod_pessoa', selected);
              onChangeFormFiltros({
                ...formFiltros,
                cod_pessoa: {
                  ...formFiltros.cod_pessoa,
                  value: selected,
                  isInvalid: selected.value === undefined,
                },
              });
              handleClearOptions();
            }}
            disabled={disabledFields}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputSelect
            label="Finalizadora"
            value={
              finalizadoras.find((item: Select) => {
                return item.value === formFiltros.cod_finalizadora.value.value;
              }) ?? {
                value: undefined,
                label: 'Informe a Finalizadora',
              }
            }
            options={[
              {
                label: 'Nenhuma',
                value: undefined,
              },
              ...finalizadoras,
            ]}
            isDisabled={disabledFields}
            placeholder="Informe a Finalizadora"
            noOptionsMessage="Nenhum registro encontrado"
            isRequired={formFiltros.cod_finalizadora.isRequired}
            setInvalid={formFiltros.cod_finalizadora.isInvalid}
            iniInicializado={false}
            onChange={(value: Select) => {
              onChangeFormFiltros({
                ...formFiltros,
                cod_finalizadora: {
                  ...formFiltros.cod_finalizadora,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </Col>
        <Col sm={12} md={4}>
          <Categoria
            value={formFiltros.cod_categoria.value}
            isRequired={formFiltros.cod_categoria.isRequired}
            setInvalid={formFiltros.cod_categoria.isInvalid}
            isDisabled={disabledFields}
            iniInicializado={false}
            tipoCategoria={tipoCategoria}
            onChange={(value: any) => {
              onChangeFormFiltros({
                ...formFiltros,
                cod_categoria: {
                  ...formFiltros.cod_categoria,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={2} className="containerTipo">
          <Field name="Tipo" defaultValue="" label="Tipo">
            {() => (
              <BtnGroupContainer>
                <Button
                  value="0"
                  className={
                    formFiltros.tipo_conta.value === 0
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  disabled={disabledFields}
                  onClick={() => {
                    handleTipoCategoria('Despesa');
                    setValue('flg_calc_juros', false);
                    onChangeFormFiltros({
                      ...formFiltros,
                      tipo_conta: {
                        ...formFiltros.tipo_conta,
                        value: 0,
                      },
                    });
                  }}
                  type="button"
                >
                  Pagar
                </Button>
                <Button
                  value="1"
                  disabled={disabledFields}
                  className={
                    formFiltros.tipo_conta.value === 1
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  onClick={() => {
                    handleTipoCategoria('Receita');
                    setValue('flg_calc_juros', true);
                    onChangeFormFiltros({
                      ...formFiltros,
                      tipo_conta: {
                        ...formFiltros.tipo_conta,
                        value: 1,
                      },
                    });
                  }}
                  type="button"
                >
                  Receber
                </Button>
              </BtnGroupContainer>
            )}
          </Field>
        </Col>
        <Col sm={12} md={2} className="containerPeriodo">
          <Field name="periodo" defaultValue="" label="Período">
            {() => (
              <BtnGroupContainer>
                <Button
                  value="0"
                  disabled={disabledFields}
                  className={
                    formFiltros.tipo_periodo.value === 0
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  onClick={() => {
                    onChangeFormFiltros({
                      ...formFiltros,
                      tipo_periodo: {
                        ...formFiltros.tipo_periodo,
                        value: 0,
                      },
                    });
                  }}
                  type="button"
                >
                  Entrada
                </Button>
                <Button
                  value="1"
                  disabled={disabledFields}
                  className={
                    formFiltros.tipo_periodo.value === 1
                      ? 'btn-group btn-group-active'
                      : 'btn-group'
                  }
                  onClick={() => {
                    onChangeFormFiltros({
                      ...formFiltros,
                      tipo_periodo: {
                        ...formFiltros.tipo_periodo,
                        value: 1,
                      },
                    });
                  }}
                  type="button"
                >
                  Vencimento
                </Button>
              </BtnGroupContainer>
            )}
          </Field>
        </Col>

        <Col sm={12} md={2} className="colDataContainer">
          <Title>Intervalo</Title>
          <ContainerDate>
            <div
              className={`${
                formFiltros.dta_ini.isInvalid && initData ? 'alert-class' : ''
              }`}
            >
              <InpuTypeDate
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const isInvalid = !moment(e.target.value).isValid();
                  onChangeFormFiltros({
                    ...formFiltros,
                    dta_ini: {
                      ...formFiltros.dta_ini,
                      value: e.target.value,
                      isInvalid,
                    },
                  });
                }}
                value={formFiltros.dta_ini.value}
                type="date"
                disabled={disabledFields}
              />
            </div>
          </ContainerDate>
        </Col>

        <Col sm={12} md={2} className="colDataContainer">
          <ContainerDate>
            <div
              className={`${
                formFiltros.dta_fim.isInvalid && initData ? 'alert-class' : ''
              }`}
            >
              <InpuTypeDate
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const isInvalid = !moment(e.target.value).isValid();
                  onChangeFormFiltros({
                    ...formFiltros,
                    dta_fim: {
                      ...formFiltros.dta_fim,
                      value: e.target.value,
                      isInvalid,
                    },
                  });
                }}
                value={formFiltros.dta_fim.value}
                type="date"
                disabled={disabledFields}
              />
            </div>
          </ContainerDate>
        </Col>

        <Col
          sm={12}
          md={4}
          className="containerButtonAction"
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <FormActions />
        </Col>
      </Row>
    </Container>
  );
};
